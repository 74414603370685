import PageTitle from '../../components/PageTitle';
import {
	Box,
	Button,
	InputAdornment,
	Skeleton,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { EmptyContainer } from '../DealsPageView/dealsPageView.styles';
import { Link as RouterLink } from 'react-router-dom';
import { useLoaderContext } from '../../context';
import React, { useEffect, useMemo, useState } from 'react';
import { FilterAlt } from '@mui/icons-material';
import { apiClient, endpoints } from '../../utils';
import { toast } from 'react-toastify';
import ReportCompanyCard from '../../components/ReportCompanyCard';
import * as XLSX from 'xlsx';
import { strings } from '../../utils/strings';
import { MaterialSymbol } from 'react-material-symbols';

const ReportPageView = () => {
	const { isLoading } = useLoaderContext();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [areCompaniesLoading, setCompaniesLoading] = useState(true);
	const [companies, setCompanies] = useState([]);
	const [filter, setFilters] = useState({
		name: '',
	});
	const filteredCompanies = useMemo(() => {
		return companies.filter((company) => {
			return filter.name ? company.Name.toLowerCase().includes(filter.name.toLowerCase()) : true;
		});
	}, [companies, filter]);

	useEffect(() => {
		const loadData = async () => {
			try {
				const res = await apiClient.get(endpoints.getInstitutionalReport);
				return res.data ?? [];
			} catch (err) {
				toast.error(strings.errorMessages.inquiry.loadMany);
				return [];
			}
		};

		if (!isLoading) {
			setCompaniesLoading(true);
			loadData()
				.then((res) => setCompanies(res))
				.finally(() => {
					setCompaniesLoading(false);
				});
		}
	}, [isLoading]);

	const isFiltersEmpty = () => {
		for (const key in filter) {
			if (filter.hasOwnProperty(key) && filter[key] !== '') {
				return false;
			}
		}
		return true;
	};

	const handleChange = (evt) => {
		const name = evt.target.name;
		const value = evt.target.value;

		switch (name) {
			case 'name':
				setFilters((prev) => ({
					...prev,
					[name]: value,
				}));
				break;
			default:
				setFilters((prev) => ({
					...prev,
					[name]: value === 'string' ? value.split(',') : value,
				}));
				break;
		}
	};

	const handleExportClick = () => {
		// Process your data (convert it to a format suitable for XLSX)
		// For instance, if you have nested arrays or objects, you might need to flatten them
		const processedData = companies.map((item) => {
			const transformedItem = {};

			transformedItem['Company'] = item.Name;

			item.fields.forEach((field) => {
				// Constructing the key using 'label'
				const key = field.label;

				// Creating a combined value from 'primary' and 'secondary'
				let value = field.primary || '';
				if (field.secondary) {
					value += ` (${field.secondary})`;
				}

				// Assigning the value to the corresponding key in the transformed item
				transformedItem[key] = value;
			});

			return transformedItem;
		});

		// Create a new workbook and a worksheet
		const workbook = XLSX.utils.book_new();
		const worksheet = XLSX.utils.json_to_sheet(processedData);

		// Add the worksheet to the workbook
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');

		// Generate buffer
		const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

		// Create a Blob and download it
		const blob = new Blob([wbout], { type: 'application/octet-stream' });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = 'report.xlsx';
		a.click();
		window.URL.revokeObjectURL(url);
	};

	return (
		<>
			<PageTitle
				breadcrumbs={[
					{
						title: 'Report',
					},
				]}
				title={'Company Watchlist'}
			/>
			<Stack
				alignItems={isMobile ? undefined : 'center'}
				spacing={1}
				justifyContent={isMobile ? undefined : 'space-between'}
				direction={isMobile ? 'column' : 'row'}
			>
				<TextField
					sx={{ width: isMobile ? undefined : '40%' }}
					variant={'outlined'}
					name={'name'}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<FilterAlt color="disabled" />
							</InputAdornment>
						),
					}}
					value={filter.name}
					onChange={handleChange}
					label={'Filter report by company name'}
					size={'medium'}
				/>
				<Button
					variant={'outlined'}
					size={'large'}
					startIcon={<MaterialSymbol icon="summarize" />}
					onClick={handleExportClick}
				>
					Export as XLSX
				</Button>
			</Stack>

			<Box mt={3}>
				{areCompaniesLoading && (
					<Stack spacing={2} width={'100%'}>
						<Skeleton variant="rectangular" height={214} />
						<Skeleton variant="rectangular" height={214} />
					</Stack>
				)}

				{!areCompaniesLoading && filteredCompanies.length === 0 && (
					<EmptyContainer>
						<Stack spacing={3} alignItems={'center'}>
							<img src={'/illustrations/dark/notFound.svg'} style={{ height: 160 }} alt="No companies" />
							<Stack spacing={1} alignItems={'center'}>
								<Typography variant={'h5'}>
									{isFiltersEmpty() ? 'Your wishlist is empty.' : 'No companies found'}
								</Typography>
								<Typography variant={'body2'}>
									{isFiltersEmpty()
										? 'Add companies to your wishlist to see a report.'
										: "We couldn't find what you searched for. Change your search criteria."}
								</Typography>
							</Stack>
							{isFiltersEmpty() && (
								<Button variant={'contained'} component={RouterLink} to={'/companies'}>
									browse companies
								</Button>
							)}
						</Stack>
					</EmptyContainer>
				)}

				{!areCompaniesLoading && filteredCompanies.length > 0 && (
					<Stack spacing={2}>
						{filteredCompanies.map((company, i) => (
							<ReportCompanyCard key={i} object={company} />
						))}
					</Stack>
				)}
			</Box>
		</>
	);
};

export default ReportPageView;
