export const strings = {
	successMessages: {
		inquiry: {
			canceled: 'Inquiry have been canceled successfully!',
			enabled: 'Inquiry have been enabled successfully!',
			deleted: 'Inquiry have been deleted successfully!',
			updated: 'Inquiry have been updated successfully!',
			saved: 'Inquiry have been saved successfully!',
			draftSellerTicketCreated: 'Draft seller ticket have been created successfully!',
			uploadsCopied: 'Uploads copied successfully!',
			sentForSignature: 'Inquiry have been sent for signature successfully!',
		},
		inquisition: {
			saved: 'Inquisition have been saved successfully!',
			duplicated: 'Inquisition have been duplicated successfully!',
		},
		investor: {
			deleted: 'Investor have been deleted successfully!',
			updated: 'Investor have been updated successfully!',
			saved: 'Investor have been saved successfully!',
		},
		accreditedInvestor: {
			deleted: 'Accredited investor have been deleted successfully!',
			updated: 'Accredited investor have been updated successfully!',
			saved: 'Accredited investor have been saved successfully!',
		},
		user: {
			granted: 'Access granted.',
			updated: 'User have been updated successfully!',
			created: 'User have been created successfully!',
			deleted: 'User have been deleted successfully!',
			impersonated: 'Check the #tokens Slack channel for the impersonation link.',
		},
		masterBid: {
			deleted: 'Master bid have been deleted successfully!',
			saved: 'Master bid have been saved successfully!',
		},
		masterOffer: {
			deleted: 'Master offer has been deleted successfully!',
			saved: 'Master offer has been saved successfully!',
		},
		bid: {
			answerSaved: 'Your answer is saved!',
		},
		company: {
			saved: 'Company have been saved successfully!',
			fundingRoundSaved: 'Funding round have been saved successfully!',
			priceHistorySaved: 'Price history have been saved successfully!',
		},
		message: {
			sentMany: 'Messages are sent successfully!',
		},
		email: {
			sentMany: 'Emails are sent successfully!',
		},
		task: {
			statusChanged: 'Status for task have been changed successfully!',
			saved: 'Task have been saved successfully!',
		},
		compliance: {
			updated: 'Compliance has been updated successfully!',
		},
		entity: {
			deleted: 'Entity have been deleted successfully!',
			updated: 'Entity have been updated successfully!',
			saved: 'Entity have been saved successfully!',
		},
		teamMember: {
			deleted: 'Team member have been deleted successfully!',
			updated: 'Team member have been updated successfully!',
			saved: 'Team member ave been saved successfully!',
		},
		chat: {
			resolved: 'Chat have been resolved successfully!',
		},
	},
	errorMessages: {
		default: 'Something’s wrong! Please try again.',
		rating: {
			update: 'Error attempting to update rating',
		},
		inquiry: {
			loadMany: 'Error loading inquiries',
			load: 'Error loading inquiry',
			cancel: 'Error attempting to cancel inquiry',
			enable: 'Error attempting to enable inquiry',
			delete: 'Error attempting to delete inquiry',
			update: 'Error attempting to update inquiry',
			save: 'Error attempting to save inquiry',
			saveAnswers: 'Error attempting to save your answers. Please, try again later.',
			copyUploads: 'Error attempting to copy uploads',
		},
		inquisition: {
			loadMany: 'Error loading inquisitions',
			load: 'Error loading inquisition',
			update: 'Error attempting to update inquisition',
			save: 'Error attempting to save inquisition',
			duplicate: 'Error attempting to duplicate inquisition',
		},
		investor: {
			loadMany: 'Error loading investors',
			load: 'Error loading investor',
			delete: 'Error attempting to delete investor',
			save: 'Error attempting to save investor',
			update: 'Error attempting to update investor',
		},
		accreditedInvestor: {
			loadMany: 'Error loading accredited investors',
			load: 'Error loading accredited investor',
			delete: 'Error attempting to delete accredited investor',
			save: 'Error attempting to save accredited investor',
			update: 'Error attempting to update accredited investor',
		},
		company: {
			follow: 'Error attempting to follow the company',
			loadMany: 'Error loading companies',
			load: 'Error loading company',
			save: 'Error attempting to save company',
			update: 'Error attempting to update company',
			fundingRounds: {
				load: 'Error loading funding rounds',
				save: 'Error attempting to save funding rounds',
				update: 'Error attempting to update funding rounds',
			},
			priceHistory: {
				load: 'Error loading price history',
				save: 'Error attempting to save price history',
				update: 'Error attempting to update price history',
				delete: 'Error attempting to delete price history',
				duplicate: 'Error attempting to duplicate the last price history entry',
			},
		},
		user: {
			emailExists: 'User with the specified email address already exists',
			load: 'Error loading user',
			loadMany: 'Error loading users',
			filter: 'Error attempting to filter users',
		},
		chat: {
			load: 'Error loading chat',
			loadMany: 'Error loading chats',
		},
		activityLogs: {
			load: 'Error loading activity logs',
		},
		masterBid: {
			delete: 'Error attempting to delete master bid',
			load: 'Error loading master bid',
			save: 'Error attempting to save master bid',
			update: 'Error attempting to update master bid',
		},
		masterOffer: {
			delete: 'Error attempting to delete master offer',
			load: 'Error loading master offer',
			save: 'Error attempting to save master offer',
			update: 'Error attempting to update master offer',
		},
		overview: {
			load: 'Error loading overview data',
		},
		windowShopper: {
			loadMany: 'Error loading window shoppers',
		},
		task: {
			load: 'Error loading task',
		},
		message: {
			send: 'Error sending a message. Please try again later.',
		},
		email: {
			send: 'Error sending an email. Please try again later.',
		},
		form: {
			submit: 'Error submitting the form',
		},
		compliance: {
			update: 'Error attempting to update compliance. Try again later.',
			saveFiles: 'Error attempting to save files',
		},
		entity: {
			loadMany: 'Error loading entities',
			delete: 'Error attempting to delete entity',
			update: 'Error attempting to update entity',
		},
		teamMember: {
			loadMany: 'Error loading team members',
			delete: 'Error attempting to delete team member',
			save: 'Error attempting to save team member',
		},
	},
};
