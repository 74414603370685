import { Route, Routes, useNavigate } from 'react-router-dom';
import LandingPageView from '../../views/LandingPageView';
import CompanyPageView from '../../views/CompanyPageView';
import ModalsView from '../../views/Modals';
import InquiryView from '../../views/InquiryPageView';
import OnboardingView from '../../views/Onboarding';
import TransactionFormView from '../../views/TransactionForm';
import {
	AdminAppProvider,
	AudioProvider,
	ChatWidgetProvider,
	PrivateAppProvider,
	PublicAppProvider,
	useAuthContext,
} from '../../context';
import ThankYouPageView from '../../views/ThankYouPageView';
import DealsPageView from '../../views/DealsPageView';
import CompaniesPageView from '../../views/CompaniesPageView';
import NotFoundPageView from '../../views/NotFoundPageView';
import ReferralPageView from '../../views/ReferralPageView';
import MessageView from '../../views/MessageView';
import AnnouncementView from '../../views/AnnouncementView';
import MessageSuccessView from '../../views/MessageSuccessView';
import CompanyPageSuccessView from '../../views/CompanyPageSuccessView';
import ReportPageView from '../../views/ReportPageView';
import AccreditationQuestionnaireView from '../../views/AccreditationQuestionnaireView';
import SettingsPageView from '../../views/SettingsPageView';
import React, { lazy, Suspense, useEffect } from 'react';
import MarketPageView from '../../views/MarketPageView';
import BidView from '../../views/BidView';
import ChatWidget from '../ChatWidget';
import ActivityFeed from '../ActivityFeed';

const AdminView = lazy(() => import('../../views/AdminView'));
const AdminChatsView = lazy(() => import('../../views/AdminChatsView'));
const AdminCompaniesView = lazy(() => import('../../views/AdminCompaniesView'));
const AdminSendToChatView = lazy(() => import('../../views/AdminSendToChatView'));
const AdminSendRetargetsView = lazy(() => import('../../views/AdminSendRetargetsView'));
const AdminActivityLogsView = lazy(() => import('../../views/AdminActivityLogsView'));
const AdminTasksView = lazy(() => import('../../views/AdminTasksView'));
const AdminDealchatsView = lazy(() => import('../../views/AdminDealchatsView'));
const AdminInquisitionsView = lazy(() => import('../../views/AdminInquisitionsView'));
const AdminOverviewView = lazy(() => import('../../views/AdminOverviewView'));
const AdminInquiriesView = lazy(() => import('../../views/AdminInquiriesView'));
const AdminUsersView = lazy(() => import('../../views/AdminUsersView'));
const AdminSignaturesView = lazy(() => import('../../views/AdminSignaturesView'));
const AdminItoView = lazy(() => import('../../views/AdminItoView'));
const AdminInvestorsView = lazy(() => import('../../views/AdminInvestorsView'));
const AdminAccreditedInvestorsView = lazy(() => import('../../views/AdminAccreditedInvestorsView'));
const AdminBidsView = lazy(() => import('../../views/AdminBidsView'));
const AdminOffersView = lazy(() => import('../../views/AdminOffersView'));
const AdminInviteBuyersToMasterOfferView = lazy(() => import('../../views/AdminInviteBuyersToMasterOfferView'));
const AdminInviteSellerToMasterBidView = lazy(() => import('../../views/AdminInviteSellerToMasterBidView'));

const AppRouter = () => {
	const { authenticated, user, isDataLoading } = useAuthContext();
	const navigate = useNavigate();

	useEffect(() => {
		if (authenticated && window.location.pathname === '/') {
			navigate('/home');
		}
	}, [navigate, authenticated]);

	const renderInstitutionalRoutes = () => {
		return (
			<>
				<Route path="/settings" element={<SettingsPageView />} />
				<Route path="/settings/compliance" element={<SettingsPageView />} />
				<Route path="/settings/team" element={<SettingsPageView />} />
				<Route path="/settings/team/:id" element={<SettingsPageView />} />
				<Route path="/settings/entities" element={<SettingsPageView />} />
				<Route path="/settings/entities/:id" element={<SettingsPageView />} />
				{user?.isLevel3 && <Route path="/market" element={<MarketPageView />} />}
			</>
		);
	};

	const renderUserRoutes = () => {
		return (
			<>
				<Route path="/" element={<DealsPageView />} />
				<Route path="/home" element={<DealsPageView />} />
				<Route path="/companies" element={<CompaniesPageView />} />
				<Route path="/company/:companyId" element={<CompanyPageView />} />
				<Route path="/company/:companyId/success" element={<CompanyPageSuccessView />} />

				<Route path="/company/:companyId/send-inquiry" element={<InquiryView />} />
				<Route path="/company/:companyId/send-inquiry/success" element={<InquiryView />} />
				<Route path="/inquiry/:inquiryId" element={<InquiryView />} />
				<Route path="/inquiry/:inquiryId/success" element={<InquiryView />} />

				<Route path="/shareholder/:recId" element={<OnboardingView />} />
				<Route path="/shareholder/:recId/success" element={<OnboardingView />} />
				<Route path="/trxquest/:recId" element={<TransactionFormView />} />
				<Route path="/trxquest/:recId/success" element={<TransactionFormView />} />
				{user?.instie ? (
					<Route path="/report" element={<ReportPageView />} />
				) : (
					<Route path="/referral" element={<ReferralPageView />} />
				)}

				<Route path="/bid/:id" element={<BidView />} />

				{user?.instie && renderInstitutionalRoutes()}

				<Route path="*" exact={true} element={<NotFoundPageView />} />
			</>
		);
	};

	const renderAdminRoutes = () => {
		return (
			<Suspense>
				<Routes>
					<Route path="/" element={<AdminView />} />
					<Route path="/admin" element={<AdminView />} />

					<Route path="/admin/message" element={<MessageView />} />
					<Route path="/admin/message/success" element={<MessageSuccessView />} />

					<Route path="/admin/send-to-chat" element={<AdminSendToChatView />} />
					<Route path="/admin/send-to-chat/:userId" element={<AdminSendToChatView />} />

					<Route path="/admin/urbietorbi" element={<AnnouncementView />} />

					<Route path="/admin/overview" element={<AdminOverviewView />} />

					<Route path="/admin/inquiries" element={<AdminInquiriesView />} />
					<Route path="/admin/inquiries/:id" element={<AdminInquiriesView />} />

					<Route path="/admin/inquisitions" element={<AdminInquisitionsView />} />
					<Route path="/admin/inquisitions/:id" element={<AdminInquisitionsView />} />

					<Route path="/admin/users" element={<AdminUsersView />} />
					<Route path="/admin/users/:id" element={<AdminUsersView />} />

					<Route path="/admin/chats" element={<AdminChatsView />} />
					<Route path="/admin/chats/:id" element={<AdminChatsView />} />

					<Route path="/admin/dealchats" element={<AdminDealchatsView />} />
					<Route path="/admin/dealchats/:type/:id" element={<AdminDealchatsView />} />

					<Route path="/admin/shoppers" element={<AdminSendRetargetsView />} />

					<Route path="/admin/ito" element={<AdminItoView />} />
					<Route path="/admin/ito/:type/:id" element={<AdminItoView />} />

					<Route path="/admin/companies" element={<AdminCompaniesView />} />
					<Route path="/admin/companies/:companyId" element={<AdminCompaniesView />} />

					<Route path="/admin/activity-logs" element={<AdminActivityLogsView />} />

					<Route path="/admin/tasks" element={<AdminTasksView />} />
					<Route path="/admin/tasks/:taskId" element={<AdminTasksView />} />

					<Route path="/admin/signatures" element={<AdminSignaturesView />} />

					<Route path="/admin/bids" element={<AdminBidsView />} />
					<Route path="/admin/bids/:id" element={<AdminBidsView />} />
					<Route path="/admin/bids/user/:id" element={<AdminBidsView />} />
					<Route path="/admin/bids/:id/invite" element={<AdminInviteSellerToMasterBidView />} />

					<Route path="/admin/offers" element={<AdminOffersView />} />
					<Route path="/admin/offers/:id" element={<AdminOffersView />} />
					<Route path="/admin/offers/user/:id" element={<AdminOffersView />} />
					<Route path="/admin/offers/:id/invite" element={<AdminInviteBuyersToMasterOfferView />} />

					<Route path="/admin/investors" element={<AdminInvestorsView />} />
					<Route path="/admin/investors/:id" element={<AdminInvestorsView />} />
					<Route path="/admin/investors/user/:id" element={<AdminInvestorsView />} />

					<Route path="/admin/accredited" element={<AdminAccreditedInvestorsView />} />
					<Route path="/admin/accredited/user/:id" element={<AdminAccreditedInvestorsView />} />
					<Route path="/admin/accredited/:id" element={<AdminAccreditedInvestorsView />} />

					{renderUserRoutes()}
				</Routes>
			</Suspense>
		);
	};

	const renderAuthenticatedRoutes = () => {
		if (user?.isAccredited || user?.shadow) {
			if (user?.isAdmin) {
				return <AdminAppProvider>{renderAdminRoutes()}</AdminAppProvider>;
			} else {
				return <Routes>{renderUserRoutes()}</Routes>;
			}
		} else {
			return (
				<Routes>
					<Route path="*" element={<AccreditationQuestionnaireView />} />
				</Routes>
			);
		}
	};

	const renderUnauthenticatedRoutes = () => {
		return (
			<>
				<Route path="/thankyou" element={<ThankYouPageView />} />
				<Route path="*" exact={true} element={<LandingPageView />} />
			</>
		);
	};

	if (!isDataLoading && user && authenticated) {
		return (
			<PrivateAppProvider>
				<AudioProvider>
					<ChatWidgetProvider>
						{renderAuthenticatedRoutes()}
						<ModalsView />
						{!user?.isAdmin && <ChatWidget />}
						<ActivityFeed />
					</ChatWidgetProvider>
				</AudioProvider>
			</PrivateAppProvider>
		);
	}

	if (!isDataLoading && !authenticated) {
		return (
			<PublicAppProvider>
				<Routes>{renderUnauthenticatedRoutes()}</Routes>
				<ModalsView />
			</PublicAppProvider>
		);
	}

	return <></>;
};

export default AppRouter;
