import { Box, Button, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Logo from '../../components/Logo';
import { useNavigate } from 'react-router-dom';

const ThankYouPageView = () => {
	const navigate = useNavigate();
	const defaultTheme = useTheme();
	const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));

	return (
		<Box marginRight={3} marginLeft={3}>
			<Logo color={defaultTheme.palette.text.primary} />
			<Stack height={'100dvh'} justifyContent={'center'}>
				<Grid container columns={isMobile ? 4 : undefined} spacing={3} alignItems={'center'}>
					{isMobile && (
						<>
							<Grid item xs={1} />
							<Grid item xs={2}>
								<img src={'/illustrations/dark/heart.svg'} style={{ width: '100%' }} alt="Thank you" />
							</Grid>
							<Grid item xs={1} />
						</>
					)}
					{!isMobile && <Grid item xs={1} />}
					<Grid item xs={isMobile ? 4 : 5}>
						<Stack spacing={isMobile ? 3 : '40px'}>
							<Stack spacing={3}>
								<Typography variant={'h4'} textAlign={isMobile ? 'center' : undefined}>
									Thank you for registering!
								</Typography>
								<Typography variant={'body1'} textAlign={isMobile ? 'center' : undefined}>
									You will receive email with detailed information.
								</Typography>
							</Stack>
							<Button
								size={'large'}
								sx={{ width: isMobile ? undefined : 200 }}
								variant={'contained'}
								onClick={() => navigate('/')}
							>
								Sign In
							</Button>
						</Stack>
					</Grid>
					{!isMobile && <Grid item xs={1} />}
					{!isMobile && (
						<Grid item xs={4}>
							<img src={'/illustrations/dark/heart.svg'} style={{ width: '100%' }} alt="Thank you" />
						</Grid>
					)}
				</Grid>
			</Stack>
		</Box>
	);
};

export default ThankYouPageView;
