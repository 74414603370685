import {
	Box,
	Breadcrumbs,
	Button,
	IconButton,
	Link,
	Skeleton,
	Stack,
	SvgIcon,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Add, Star, StarBorder } from '@mui/icons-material';
import { grey, yellow } from '@mui/material/colors';

const CompanyPageTitle = (props) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { isLoading = false, isOnlyBreadcrumbs = false } = props;
	const { breadcrumbs = [], title, infoItems, favoriteButton, extraButton } = props;

	return (
		<Stack
			spacing={isMobile ? '6px' : 3}
			direction={'column'}
			paddingTop={isMobile ? 2 : 3}
			paddingBottom={isMobile ? 2 : 3}
		>
			{isLoading ? (
				<Skeleton variant="rectangular" width={isMobile ? 225 : 200} height={24} />
			) : (
				<Breadcrumbs>
					{breadcrumbs.map((breadcrumb, i) => {
						if (breadcrumb.link) {
							return (
								<Link key={i} component={RouterLink} to={breadcrumb.link} underline="hover" color="inherit">
									{breadcrumb.title}
								</Link>
							);
						} else {
							return (
								<Typography key={i} color="text.primary">
									{breadcrumb.title}
								</Typography>
							);
						}
					})}
				</Breadcrumbs>
			)}
			{!isOnlyBreadcrumbs &&
				(Boolean(title) || Boolean(favoriteButton) || Boolean(extraButton) || Boolean(infoItems)) && (
					<Stack spacing={1} direction={'column'}>
						<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
							<Stack
								spacing={2}
								direction={'row'}
								alignItems={isMobile ? 'center' : 'flex-end'}
								justifyContent={isMobile ? 'space-between' : undefined}
								width={isMobile ? '100%' : undefined}
							>
								{isLoading ? (
									<Skeleton variant="rectangular" width={isMobile ? '50%' : 356} height={42} />
								) : (
									title && (
										<Typography variant={'h4'} data-cy={'company-title'}>
											{title}
										</Typography>
									)
								)}
								{favoriteButton &&
									!isLoading &&
									(!isMobile ? (
										<Button
											size="medium"
											variant="text"
											color="inherit"
											sx={{ color: grey[500] }}
											startIcon={
												favoriteButton.value ? (
													<Star sx={{ color: yellow[800] }} />
												) : (
													<StarBorder sx={{ color: grey[500] }} />
												)
											}
											onClick={favoriteButton.onChange}
										>
											{favoriteButton.value ? 'Remove from watchlist' : 'Add to watchlist'}
										</Button>
									) : (
										<IconButton size={'large'} onClick={favoriteButton.onChange}>
											{favoriteButton.value ? (
												<Star sx={{ color: yellow[800] }} fontSize={'large'} />
											) : (
												<StarBorder sx={{ color: grey[500] }} fontSize={'large'} />
											)}
										</IconButton>
									))}
							</Stack>
							{extraButton && !isMobile ? (
								isLoading ? (
									<Skeleton variant="rectangular" width={180} height={36} />
								) : (
									<Button
										size="medium"
										variant="contained"
										color="primary"
										startIcon={<Add />}
										onClick={extraButton?.onClick}
										data-cy={extraButton?.dataCy}
									>
										{extraButton?.label}
									</Button>
								)
							) : (
								<></>
							)}
							{extraButton && isMobile && !isLoading && (
								<Button
									size="large"
									variant="contained"
									color="primary"
									startIcon={<Add />}
									onClick={extraButton?.onClick}
									data-cy={extraButton?.dataCy}
									sx={{
										position: 'fixed',
										bottom: 24,
										left: 24,
										right: 24,
										zIndex: 1051,
										height: 48,
										boxShadow: theme.shadows[24],
									}}
								>
									{extraButton?.label}
								</Button>
							)}
						</Stack>
						{isLoading ? (
							<Skeleton variant="rectangular" width={isMobile ? '100%' : 662} height={28} />
						) : (
							infoItems &&
							infoItems.length && (
								<Box
									sx={{
										display: 'flex',
										flexWrap: 'wrap',
									}}
								>
									{infoItems.map((infoItem, i) => (
										<Stack
											key={i}
											spacing={1}
											direction={'row'}
											alignItems={'center'}
											style={{ marginRight: 24, marginBottom: 8 }}
										>
											<SvgIcon
												component={infoItem.icon}
												sx={{
													color: infoItem.link || infoItem.mailto ? 'primary.main' : 'text.secondary',
													fontSize: '16px',
												}}
											/>
											{infoItem.link ? (
												<Link
													href={infoItem.link}
													target={'_blank'}
													rel={'noreferrer'}
													underline={'hover'}
													fontSize={14}
												>
													{infoItem.title}
												</Link>
											) : infoItem.mailto ? (
												<Link href={`mailto:${infoItem.mailto}`} underline={'hover'} fontSize={14}>
													{infoItem.mailto}
												</Link>
											) : (
												<Typography variant={'body2'} sx={{ color: 'text.secondary' }}>
													{infoItem.title}
												</Typography>
											)}
										</Stack>
									))}
								</Box>
							)
						)}
					</Stack>
				)}
		</Stack>
	);
};

CompanyPageTitle.propTypes = {
	isLoading: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.string,
	breadcrumbs: PropTypes.arrayOf(PropTypes.object),
	infoItems: PropTypes.arrayOf(PropTypes.object),
	favoriteButton: PropTypes.object,
};

export default CompanyPageTitle;
