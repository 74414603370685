import {
	Accordion,
	AccordionDetails,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Grid,
	Link,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ExpandLess, TrendingDown, TrendingUp } from '@mui/icons-material';

const ReportCompanyCard = ({ object }) => {
	const [expanded, setExpanded] = useState(false);
	const { id, Name, trend, fields } = object;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const openItemNumber = isMobile ? 4 : 5;

	const handleExpand = () => {
		setExpanded((prevState) => !prevState);
	};

	const renderItems = (startIndex, endIndex) => {
		const fieldsToRender = fields.slice(startIndex, endIndex);

		if (fieldsToRender && fieldsToRender.length > 0) {
			return fieldsToRender.map((field) => {
				return (
					<Grid item xs={isMobile ? 5 : 2} key={field.label}>
						<Stack direction={'column'}>
							<Typography variant={'body2'} color={'text.secondary'}>
								{field.label}
							</Typography>
							<Stack spacing={'2px'} direction={'column'} pt={'2px'}>
								<Stack direction={'row'} alignItems={'baseline'} flexWrap={'wrap'}>
									<Stack spacing={1} direction={'row'} alignItems={'center'} mr={1}>
										{field.trend === 'up' && <TrendingUp sx={{ color: 'success.main', fontSize: 20 }} />}
										{field.trend === 'down' && <TrendingDown sx={{ color: 'error.main', fontSize: 20 }} />}
										<Typography
											variant={'body1'}
											fontWeight={500}
											flexShrink={0}
											color={field.trend === 'up' ? 'success.main' : field.trend === 'down' ? 'error.main' : undefined}
										>
											{field.primary}
										</Typography>
									</Stack>
									<Typography variant={'caption'} color={'text.secondary'} fontStyle={'italic'} flexShrink={0}>
										{field.secondary}
									</Typography>
								</Stack>
							</Stack>
						</Stack>
					</Grid>
				);
			});
		}
	};

	return (
		<Card key={id}>
			<CardHeader
				title={
					<Stack alignItems={'center'} direction={'row'} spacing={1}>
						<Link
							component={RouterLink}
							underline={'hover'}
							to={`/company/${id}`}
							color={trend === 'up' ? 'success.main' : trend === 'down' ? 'error.main' : undefined}
						>
							{Name}
						</Link>
						{trend === 'up' && <TrendingUp sx={{ color: 'success.main', fontSize: 24 }} />}
						{trend === 'down' && <TrendingDown sx={{ color: 'error.main', fontSize: 24 }} />}
					</Stack>
				}
			/>
			<CardContent sx={{ paddingTop: 1, paddingBottom: 0 }}>
				<Accordion expanded={expanded} sx={{ boxShadow: 'none' }}>
					<Grid container columnSpacing={4} columns={10} rowSpacing={3}>
						{renderItems(0, openItemNumber)}
					</Grid>
					<AccordionDetails sx={{ padding: 0, paddingTop: 3 }}>
						<Grid container columnSpacing={4} columns={10} rowSpacing={3}>
							{renderItems(openItemNumber, fields.length)}
						</Grid>
					</AccordionDetails>
				</Accordion>
			</CardContent>
			<CardActions sx={{ justifyContent: 'flex-end' }}>
				<Button
					size={isMobile ? 'large' : 'small'}
					disableRipple={true}
					disableElevation={true}
					endIcon={expanded ? <ExpandLess /> : <ExpandMoreIcon />}
					onClick={handleExpand}
				>
					{expanded ? 'Hide' : 'Show More'}
				</Button>
			</CardActions>
		</Card>
	);
};

export default ReportCompanyCard;
