import React, { useMemo, useRef, useState } from 'react';
import {
	Autocomplete,
	Box,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	Link,
	MenuItem,
	Radio,
	RadioGroup,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { apiClient, endpoints } from '../../utils';
import { getData } from 'country-list';
import { initialValues, schema } from './registerForm.constants';
import InputField from '../FormComponents/InputField';
import ForwardInputField from '../FormComponents/ForwardInputField';
import Checkbox from '../FormComponents/Checkbox';
import Select from '../FormComponents/Select';
import CountrySelect from '../FormComponents/CountrySelect';
import FormNDA from '../FormNDA';
import { Link as RouterLink } from 'react-router-dom';
import InfoBlock from '../InfoBlock';
import LoadingButton from '@mui/lab/LoadingButton';
import ThankYouBlock from '../ThankYouBlock';
import { CenterWrapper } from '../LandingPage/loginPage.styles';
import UnicornLogo from '../UnicornLogo';
import { constants } from '../../utils/constants';
import { usePublicAppContext } from '../../context';
import FormikErrorFocus from 'formik-error-focus';
import { strings } from '../../utils/strings';
import { useDebouncedCallback } from 'use-debounce';

const userOptions = constants.USER.TYPE.map((type) => ({
	label: type,
	value: type,
}));

const RegisterForm = () => {
	const emailRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [isSuccess, setSuccess] = useState(false);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [userType, setUserType] = useState('An Individual Shareholder');
	const { companies } = usePublicAppContext();

	const params = new Proxy(new URLSearchParams(window.location.search), {
		get: (searchParams, prop) => searchParams.get(prop),
	});

	const referralId = params[endpoints.referralParam];

	const handleSubmit = async (values, { setFieldError }) => {
		setLoading(true);
		setUserType(userType);

		// trim
		Object.keys(values).forEach(
			(k) => (values[k] = typeof values[k] === 'string' || values[k] instanceof String ? values[k].trim() : values[k])
		);
		const { firstName, lastName, email, userType, residency, newsletter, Report, companyName, accountType } = values;
		const refererPath = window.location.pathname;

		let payload = {
			'First Name': firstName,
			'Last Name': lastName,
			Email: email.toLowerCase(),
			Type: userType,
			'Account Type': accountType,
			Location: residency,
			Newsletter: newsletter,
			Report: Report,
			Company: companyName,
			questionnaire: JSON.stringify(values),
		};

		if (values.userType === 'An Individual Shareholder') {
			payload.contactMethod = 'Email';
		}

		if (referralId) {
			payload.referral = referralId;
		}

		if (refererPath !== '/' && refererPath !== '/signin' && refererPath !== '/signup') {
			payload.referer = refererPath;
		}

		try {
			const res = await apiClient.post(endpoints.signup, payload);

			if (res.status === 200) {
				setSuccess(true);
				window.lintrk('track', { conversion_id: 12014650 });
			}
		} catch (err) {
			if (err.response.status === 409) {
				toast.error(strings.errorMessages.user.emailExists);
				setFieldError('email', 'Please enter a valid email');
				emailRef.current.focus();
			}
			console.log('handleRegister.err', err);
		} finally {
			setLoading(false);
		}
	};

	const countries = useMemo(() => getData().map((country) => ({ label: country.name, code: country.code })), []);

	const validateEmail = useDebouncedCallback(async (value) => {
		if (value) {
			const res = await apiClient.post(endpoints.emailExists, { email: value });

			if (res.data) {
				return 'This email is already associated with an account.';
			}
		} else {
			return 'Required';
		}
	}, 1500);

	const signInButton = (
		<Stack direction={'row'} spacing={'6px'} justifyContent={'flex-end'}>
			<Typography variant={'body2'} sx={{ color: 'text.primary' }}>
				Already have an account?
			</Typography>
			<Link component={RouterLink} to={isMobile ? '/signin' + window.location.search : '/' + window.location.search}>
				<Typography variant="body2" sx={{ color: 'primary.main' }}>
					Sign in
				</Typography>
			</Link>
		</Stack>
	);

	if (isSuccess) {
		return (
			<CenterWrapper container>
				<Box maxWidth={'60%'}>
					<ThankYouBlock
						title={'Thank you for registering!'}
						description={
							userType === 'An Individual Shareholder'
								? 'You will receive email with detailed information.'
								: 'Thanks so much for joining ShareWell and we look forward to serving your secondary market data and trading interests. Give us a day or two to add you to the platform as we conduct a quick account review. We appreciate your patience!'
						}
						buttonLink={'/'}
						buttonText={'sign In'}
					/>
				</Box>
			</CenterWrapper>
		);
	}

	return (
		<Stack spacing={8} sx={!isMobile ? { padding: '16px 0 80px' } : undefined}>
			{!isMobile && <Box marginRight={4}>{signInButton}</Box>}
			<Stack spacing={isMobile ? 2 : 3} alignItems={'center'}>
				<Link to={'/'} component={RouterLink}>
					<UnicornLogo width={isMobile ? 100 : 120} height={isMobile ? 100 : 120} />
				</Link>
				<Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema} validateOnBlur={true}>
					{(formikProps) => {
						const { isSubmitting, setFieldValue, values, handleChange, errors, touched } = formikProps;

						return (
							<Form autoComplete="off" style={{ width: '100%' }}>
								<Box>
									<Grid container spacing={3} justifyContent={'center'}>
										<Grid item xs={isMobile ? 12 : 8}>
											<Stack spacing={isMobile ? '40px' : 4}>
												<Stack spacing={isMobile ? 1 : '6px'}>
													<Typography variant={'h5'} color={'text.primary'} textAlign={isMobile && 'center'}>
														Create new account
													</Typography>
													<Typography variant={'body2'} color={'text.secondary'} textAlign={isMobile && 'center'}>
														Let us know a few more details and we will take it from there.
													</Typography>
												</Stack>

												<InfoBlock title={'Basic Info'}>
													<Grid item xs={isMobile ? 12 : 6}>
														<Select
															fullWidth
															name={'userType'}
															label={'Your role'}
															required
															showAsterisk={false}
															onChange={(e) => {
																setFieldValue('userType', e.target.value);

																if (e.target.value === userOptions[0].value) {
																	setFieldValue('contactMethod', 'Email');
																	setFieldValue('follows', []);
																	setFieldValue('reasons', []);
																}
															}}
															data-cy={'registration-role'}
														>
															{userOptions.map((opt) => (
																<MenuItem value={opt.value} key={opt.value}>
																	{opt.label}
																</MenuItem>
															))}
														</Select>
													</Grid>
													<Grid
														item
														xs={isMobile ? 12 : 6}
														display={
															isMobile && !(values.userType && values.userType !== userOptions[0].value) && 'none'
														}
													>
														{values.userType && values.userType !== userOptions[0].value && (
															<InputField
																name="companyName"
																fullWidth
																label={'Name of the Company or Firm'}
																required
																showAsterisk={false}
															/>
														)}
													</Grid>

													{values.userType && values.userType === userOptions[0].value && (
														<Grid item xs={12}>
															<Autocomplete
																multiple
																options={companies}
																getOptionLabel={(option) => option['Legal Name']}
																isOptionEqualToValue={(option, value) => {
																	return option['Record Id'] === value['Record Id'];
																}}
																onChange={(_, value) => {
																	setFieldValue('follows', value);
																}}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		label="Select the companies for your portfolio"
																		required={values.userType && values.userType === userOptions[0].value}
																		InputLabelProps={{
																			required: false,
																		}}
																		inputProps={{
																			...params.inputProps,
																			required: values.follows.length === 0,
																		}}
																		data-cy={'registration-companies'}
																	/>
																)}
															/>
														</Grid>
													)}

													<Grid item xs={isMobile ? 12 : 6}>
														<InputField
															name="firstName"
															fullWidth
															label={'First name'}
															required
															showAsterisk={false}
															data-cy={'registration-firstname'}
														/>
													</Grid>
													<Grid item xs={isMobile ? 12 : 6}>
														<InputField
															name="lastName"
															fullWidth
															label={'Last name'}
															required
															showAsterisk={false}
															data-cy={'registration-lastname'}
														/>
													</Grid>

													{values.userType && values.userType === userOptions[0].value && (
														<Grid item xs={12}>
															<FormControl component="fieldset" error={touched.reasons && Boolean(errors.reasons)}>
																<FormLabel component="legend">
																	What's best describes your reasons for signing up?
																</FormLabel>
																<FieldArray
																	name="reasons"
																	render={(arrayHelpers) =>
																		[
																			{
																				label: 'I want to monitor of my portfolio’s performance',
																				value: 'Looker',
																			},
																			{
																				label: 'I want to track market price movements',
																				value: 'Timer',
																			},
																			{
																				label: 'I am seeking optimal opportunities to trade',
																				value: 'Trader',
																			},
																		].map((item) => (
																			<Checkbox
																				key={item.value}
																				name="reasons"
																				required={values.reasons.length === 0}
																				value={item}
																				label={
																					<Typography variant={'body1'} color={'text.primary'} component={'span'}>
																						{item.label}
																					</Typography>
																				}
																				disabled={isSubmitting}
																				checked={values.reasons.includes(item.value)}
																				onChange={(e) => {
																					if (e.target.checked) {
																						arrayHelpers.push(item.value);
																					} else {
																						const idx = values.reasons.indexOf(item.value);
																						arrayHelpers.remove(idx);
																					}
																				}}
																			/>
																		))
																	}
																/>
																{touched.reasons && errors.reasons && <FormHelperText>{errors.reasons}</FormHelperText>}
															</FormControl>
														</Grid>
													)}
												</InfoBlock>

												<InfoBlock title={'Contact information'}>
													<Grid item xs={isMobile ? 12 : 6}>
														<CountrySelect
															label="Country of residence"
															fullWidth
															name="residency"
															value={values.residency}
															required
															options={countries}
															handleChange={handleChange}
															setFieldValue={setFieldValue}
															showAsterisk={false}
														/>
													</Grid>
													<Grid
														item
														xs={isMobile ? 12 : 6}
														display={
															isMobile &&
															!(values.userType && values.userType !== 'An Individual Shareholder') &&
															'none'
														}
													>
														{values.userType && values.userType !== 'An Individual Shareholder' && (
															<CountrySelect
																label="Country where your firm is headquartered"
																fullWidth
																name="firmHeadquarters"
																value={values.firmHeadquarters}
																required
																options={countries}
																handleChange={handleChange}
																setFieldValue={setFieldValue}
																showAsterisk={false}
															/>
														)}
													</Grid>
													<Grid item xs={isMobile ? 12 : 6}>
														<ForwardInputField
															type="email"
															name="email"
															fullWidth
															ref={emailRef}
															label={'Email'}
															required
															showAsterisk={false}
															validate={validateEmail}
														/>
													</Grid>
													{!isMobile && <Grid item xs={6} />}
													<Grid item xs={12}>
														<FormControl>
															<FormLabel id="contact-method-label">Preferred method of contact</FormLabel>
															<RadioGroup
																required
																name={'contactMethod'}
																value={values.contactMethod}
																onChange={(event) => {
																	setFieldValue('contactMethod', event.currentTarget.value);
																}}
															>
																<FormControlLabel
																	value="Email"
																	label="Email"
																	control={<Radio disabled={isSubmitting} />}
																	disabled={isSubmitting}
																	sx={{ color: 'text.primary' }}
																/>
																<FormControlLabel
																	value="Phone"
																	label="Phone"
																	control={<Radio disabled={isSubmitting} />}
																	disabled={isSubmitting || values.userType === 'An Individual Shareholder'}
																	sx={{ color: 'text.primary' }}
																/>
															</RadioGroup>
														</FormControl>
													</Grid>
													{values.contactMethod && values.contactMethod === 'Phone' && (
														<>
															<Grid item xs={isMobile ? 12 : 6}>
																<InputField
																	name="phoneNumber"
																	fullWidth
																	label={'Phone number'}
																	required
																	showAsterisk={false}
																/>
															</Grid>
														</>
													)}
													<Grid item xs={12}>
														<Stack direction={'column'}>
															<Checkbox
																name="newsletter"
																checked={Boolean(values.newsletter)}
																component={'span'}
																label={
																	<Typography variant={'body1'} sx={{ color: 'text.primary' }}>
																		Subscribe to our newsletter
																	</Typography>
																}
															/>
															{values.userType && values.userType !== userOptions[0].value && (
																<Checkbox
																	name="Report"
																	checked={Boolean(values.Report)}
																	label={
																		<Typography variant={'body1'} sx={{ color: 'text.primary' }} component={'span'}>
																			Subscribe to the weekly report
																		</Typography>
																	}
																/>
															)}
														</Stack>
													</Grid>
													{values.userType && values.userType !== userOptions[0].value && (
														<Grid item xs={isMobile ? 12 : 6}>
															<Select
																fullWidth
																name={'accountType'}
																label={'Account Type'}
																required
																showAsterisk={false}
																onChange={(e) => {
																	setFieldValue('accountType', e.target.value);
																}}
															>
																{constants.USER.ACCOUNT_TYPE.map((opt) => (
																	<MenuItem value={opt} key={opt}>
																		{opt}
																	</MenuItem>
																))}
															</Select>
														</Grid>
													)}
												</InfoBlock>

												{values.userType && values.userType !== 'An Institutional Firm' && (
													<InfoBlock title={'Risk Factors and Data Access Agreement'}>
														<Grid item xs={12}>
															<Stack spacing={'20px'}>
																<Typography variant={'body2'} color={'text.secondary'}>
																	Read and Agree to the Data Access Agreement and Risk Factors (Saxon Weber Ventures)
																</Typography>

																<Box pl={isMobile ? 1 : 0}>
																	<FormNDA />
																</Box>

																<Box>
																	<Grid container spacing={3}>
																		<Grid item xs={isMobile ? 12 : 6}>
																			<InputField
																				name="fullName"
																				fullWidth
																				label={'Full Name'}
																				required
																				showAsterisk={false}
																			/>
																		</Grid>
																		<Grid item xs={isMobile ? 12 : 6}>
																			<InputField
																				name="date"
																				fullWidth
																				label={'Effective date'}
																				disabled
																				value={new Date().toLocaleDateString()}
																				required
																				showAsterisk={false}
																			/>
																		</Grid>
																	</Grid>
																</Box>

																<Box>
																	<Checkbox
																		name="nda"
																		required
																		label={
																			<Typography variant={'body1'} sx={{ color: 'text.primary' }} component={'span'}>
																				I have read, understood and agreed to the Risk Factors and Data Access Terms.
																			</Typography>
																		}
																	/>
																</Box>
															</Stack>
														</Grid>
													</InfoBlock>
												)}

												<Stack spacing={6}>
													<Checkbox
														name="termsOfService"
														checked={Boolean(values.termsOfService)}
														required
														label={
															<>
																<Typography
																	variant={'body1'}
																	color={'text.primary'}
																	component={'span'}
																	marginRight={'6px'}
																>
																	I accept the ShareWell | SaxonWeber
																</Typography>
																<Link href="https://www.saxonweber.com/terms" target="_blank" rel="noreferrer">
																	Terms of Use and Privacy Policy
																</Link>
															</>
														}
													/>

													<Stack spacing={3} alignItems={isMobile ? 'center' : 'flex-start'}>
														<LoadingButton
															variant="contained"
															type="submit"
															size={'large'}
															sx={!isMobile ? { width: 280 } : undefined}
															fullWidth={isMobile}
															loading={loading}
															endIcon={<></>}
															loadingPosition={'end'}
															data-cy={'registration-submit'}
														>
															Create account
														</LoadingButton>
														{signInButton}
													</Stack>
												</Stack>
											</Stack>
										</Grid>
									</Grid>
								</Box>
								<FormikErrorFocus offset={0} align={'top'} focusDelay={0} formik={formikProps} />
							</Form>
						);
					}}
				</Formik>
			</Stack>
		</Stack>
	);
};

export default RegisterForm;
