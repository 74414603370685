import { Box, Button, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Logo from '../../components/Logo';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../context';

const NotFoundPageView = () => {
	const defaultTheme = useTheme();
	const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));
	const { authenticated } = useAuthContext();

	const content = (
		<Grid container columns={isMobile ? 4 : undefined} spacing={3} alignItems={'center'}>
			{isMobile && (
				<>
					<Grid item xs={1} />
					<Grid item xs={2}>
						<img src={'/illustrations/dark/warning.svg'} style={{ width: '100%' }} alt="Not found" />
					</Grid>
					<Grid item xs={1} />
				</>
			)}

			{!isMobile && <Grid item xs={1} />}
			<Grid item xs={isMobile ? 4 : 6}>
				<Stack spacing={isMobile ? 3 : '40px'}>
					<Typography variant={'h1'} color={'primary.main'} textAlign={isMobile ? 'center' : undefined}>
						404
					</Typography>
					<Stack spacing={2}>
						<Typography variant={'h4'} textAlign={isMobile ? 'center' : undefined}>
							Oops! Page not found
						</Typography>
						<Typography variant={'body1'} textAlign={isMobile ? 'center' : undefined}>
							The page you are looking for doesn’t exist. Come back to the homepage.
						</Typography>
					</Stack>
					<Button
						size={'large'}
						variant={'contained'}
						sx={{ width: isMobile ? undefined : 200 }}
						component={Link}
						to={'/'}
					>
						go back home
					</Button>
				</Stack>
			</Grid>
			{!isMobile && <Grid item xs={1} />}
			{!isMobile && (
				<Grid item xs={3}>
					<img src={'/illustrations/dark/warning.svg'} style={{ width: '100%' }} alt="Thank you" />
				</Grid>
			)}
		</Grid>
	);

	if (authenticated) {
		return (
			<Stack height={'90dvh'} justifyContent={'center'}>
				{content}
			</Stack>
		);
	}

	return (
		<Box marginRight={3} marginLeft={3}>
			<Logo color={defaultTheme.palette.text.primary} />
			<Stack height={'100dvh'} justifyContent={'center'}>
				{content}
			</Stack>
		</Box>
	);
};

export default NotFoundPageView;
