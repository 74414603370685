import PageTitle from '../../components/PageTitle';
import { Autocomplete, Grid, TextField } from '@mui/material';
import InfoBlock from '../../components/InfoBlock';
import { useState } from 'react';
import { apiClient, endpoints } from '../../utils';
import { toast } from 'react-toastify';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { constants } from '../../utils/constants';
import { strings } from '../../utils/strings';

const ConfirmDialog = ({ open, onClose, onConfirm }) => {
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Confirm Action</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Ready to echo through the digital megaphone? This message will reach the eyes and screens of EVERY USER on the
					platform! Still want to proceed?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button onClick={onConfirm} autoFocus>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const AnnouncementView = () => {
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [message, setMessage] = useState('');
	const [link, setLink] = useState('');
	const [selectedUserType, setSelectedUserType] = useState([]);
	const [isSendingLoading, setSendingLoading] = useState(false);

	const navigate = useNavigate();

	const handleConfirmOpen = () => {
		setConfirmOpen(true);
	};

	const handleConfirmClose = () => {
		setConfirmOpen(false);
	};

	const handleConfirm = async () => {
		handleConfirmClose();
		setSendingLoading(true);
		const body = {
			message: message.trim(),
			link: link.trim() ? link.trim() : undefined,
			type: selectedUserType,
		};

		try {
			const res = await apiClient.post(endpoints.sendUrbiEtOrbi, body);

			if (res) {
				navigate('/admin/message/success');
			}
		} catch {
			toast.error(strings.errorMessages.message.send);
		} finally {
			setSendingLoading(false);
		}
	};

	const sendMessage = async () => {
		setSendingLoading(true);
		const body = {
			message: message.trim(),
			link: link.trim() ? link.trim() : undefined,
		};

		try {
			const res = await apiClient.post(endpoints.sendUrbiEtOrbi, body);

			if (res) {
				navigate('/admin/message/success');
			}
		} catch {
			toast.error(strings.errorMessages.message.send);
		} finally {
			setSendingLoading(false);
		}
	};

	return (
		<>
			<PageTitle
				breadcrumbs={[
					{
						title: 'Admin',
						link: '/admin',
					},
					{
						title: 'Urbi & Orbi',
					},
				]}
				title={'Send system-wide message'}
			/>
			<InfoBlock title={'Message'}>
				<Grid item xs={8}>
					<Autocomplete
						multiple
						options={constants.USER.TYPE}
						getOptionLabel={(option) => option}
						isOptionEqualToValue={(option, value) => option === value}
						value={selectedUserType}
						onChange={(_, value) => {
							setSelectedUserType(value);
						}}
						renderInput={(params) => <TextField {...params} label="User Type" placeholder="Select user type" />}
					/>
				</Grid>
				<Grid item xs={8}>
					<TextField
						label="Enter your message"
						autoComplete="off"
						rows={5}
						multiline={true}
						fullWidth
						helperText="Enter message to be sent to all users"
						value={message}
						onChange={(event) => {
							setMessage(event.target.value);
						}}
					/>
				</Grid>
				<Grid item xs={8}>
					<TextField
						label="Add link (optional)"
						autoComplete="off"
						fullWidth
						value={link}
						onChange={(event) => {
							setLink(event.target.value);
						}}
					/>
				</Grid>
				<Grid item xs={8}>
					<LoadingButton
						variant={'contained'}
						disabled={message.trim() === ''}
						onClick={handleConfirmOpen}
						loading={isSendingLoading}
					>
						Send system-wide message
					</LoadingButton>
					<Button onClick={handleConfirmOpen}>Send Message</Button>
					<ConfirmDialog open={confirmOpen} onClose={handleConfirmClose} onConfirm={handleConfirm} />
				</Grid>
			</InfoBlock>
		</>
	);
};

export default AnnouncementView;
