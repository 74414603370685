import PageTitle from '../../components/PageTitle';
import {
	alpha,
	Autocomplete,
	Badge,
	Card,
	Checkbox,
	Chip,
	IconButton,
	Link,
	Stack,
	SwipeableDrawer,
	TableContainer,
	TextField,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { apiClient, endpoints, isValidNumber } from '../../utils';
import { toast } from 'react-toastify';
import { usePrivateAppContext } from '../../context';
import * as d3 from 'd3-latest';
import LoadingButton from '@mui/lab/LoadingButton';
import { strings } from '../../utils/strings';
import { constants } from '../../utils/constants';
import {
	DataGridPro,
	GRID_CHECKBOX_SELECTION_COL_DEF,
	GridFilterInputMultipleSingleSelect,
	GridFilterInputSingleSelect,
	GridToolbarContainer,
	GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { GRID_CHECKBOX_SELECTION_FIELD } from '@mui/x-data-grid/colDef/gridCheckboxSelectionColDef';
import {
	compareFilterModels,
	decodeFilterModel,
	decodePaginationModel,
	decodeSortModel,
	transformModelsToObject,
} from '../../utils/dataGridUtils';
import { Puller } from '../../components/CompanyList/companyList.styles';
import { FilterAlt } from '@mui/icons-material';

const blockSizes = [
	{ valueFrom: 0, valueTo: 500000, label: '< $500K' },
	{ valueFrom: 500000, valueTo: 1000000, label: '$500k - $1m' },
	{ valueFrom: 1000000, valueTo: 5000000, label: '$1m - $5m' },
	{ valueFrom: 5000000, valueTo: null, label: '> $5m' },
];

const MarketPageView = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [isBuyingLoading, setBuyingLoading] = useState(false);
	const navigate = useNavigate();
	const { setCurrent } = usePrivateAppContext();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	//filters
	const [isMobileFiltersOpen, setMobileFiltersOpen] = useState(false);
	const arrayFilterKeys = ['companyIds'];
	const [isReadyToFilter, setReadyToFilter] = useState(false);
	const { companies } = usePrivateAppContext();
	const [filterModel, setFilterModel] = useState({ items: [] });

	//pagination
	const [totalDataLength, setTotalDataLength] = useState(0);
	const defaultPaginationModel = { page: 0, pageSize: 50 };
	const [paginationModel, setPaginationModel] = useState(defaultPaginationModel);

	//sorting
	const defaultSortModel = { field: 'Created At', sort: 'desc' };
	const [sortModel, setSortModel] = useState([defaultSortModel]);

	//data
	const [isLoading, setLoading] = useState(true);
	const [inquiries, setInquiries] = useState([]);
	const [selectedInquiries, setSelectedInquiries] = useState([]);

	const columns = [
		{
			...GRID_CHECKBOX_SELECTION_COL_DEF,
			hideable: false,
			renderHeader: () => false,
			renderCell: (params) => {
				const isItemSelected = isSelected(params.row);
				const isItemDisabled = selectedInquiries.length
					? selectedInquiries[0].fields['Company Id'][0] !== params.row.fields['Company Id'][0]
					: false;

				return isItemDisabled ? (
					<Tooltip title="You can't choose inquiries of multiple companies" arrow placement="right">
						<span>
							<Checkbox disabled />
						</span>
					</Tooltip>
				) : (
					<Checkbox color="primary" checked={isItemSelected} onClick={(event) => onSelectOne(event, params.row)} />
				);
			},
		},
		{
			field: 'companyIds',
			headerName: 'Company',
			flex: isMobile ? undefined : 1,
			width: isMobile ? 150 : undefined,
			sortable: false,
			hideable: false,
			renderCell: (params) => (
				<Link
					underline="hover"
					component={RouterLink}
					to={`/company/${params.row.fields['Company Id']}`}
					target="_blank"
				>
					{params.row.fields['Company Name']}
				</Link>
			),
			type: 'singleSelect',
			valueOptions: companies
				? companies.map((company) => {
						return {
							value: company['Record Id'],
							label: company['Legal Name'],
						};
				  })
				: [],
			filterOperators: [
				{
					label: 'is any',
					value: 'is any',
					InputComponent: GridFilterInputMultipleSingleSelect,
				},
			],
		},
		{
			field: 'blockType',
			headerName: 'Block Type',
			flex: isMobile ? undefined : 1,
			width: isMobile ? 150 : undefined,
			hideable: false,
			renderCell: (params) =>
				params.row.fields.isInst ? (
					<Chip label="Institutional" color="secondary" size="small" />
				) : (
					<Chip label="Retail" color="info" size="small" />
				),
			type: 'singleSelect',
			filterOperators: [
				{
					label: 'is',
					value: 'is',
					InputComponent: GridFilterInputSingleSelect,
				},
			],
			valueOptions: constants.USER.USER_STAGE_GLOBAL,
		},
		{
			field: 'Action',
			headerName: 'Bid/Ask',
			flex: isMobile ? undefined : 1,
			width: isMobile ? 150 : undefined,
			hideable: false,
			filterable: false,
			renderCell: (params) =>
				params.row.fields.Action === 'sell' ? (
					<Chip label="Ask" color="warning" size="small" />
				) : (
					<Chip label="Bid" color="success" size="small" />
				),
		},
		{
			field: 'Public Status',
			headerName: 'Status',
			hideable: false,
			filterable: false,
			flex: isMobile ? undefined : 1,
			width: isMobile ? 150 : undefined,
			valueGetter: (_, row) => row.fields['Public Status'],
		},
		{
			field: 'Type',
			filterable: false,
			headerName: 'Share Type',
			flex: isMobile ? undefined : 1,
			width: isMobile ? 150 : undefined,
			hideable: false,
			valueGetter: (_, row) => row.fields.Type,
		},
		{
			field: 'Transaction type',
			headerName: 'Transaction Type',
			filterable: false,
			flex: isMobile ? undefined : 1,
			width: isMobile ? 150 : undefined,
			hideable: false,
			valueGetter: (_, row) => row.fields['Transaction type'],
		},
		{
			field: 'Price',
			headerName: 'Price',
			filterable: false,
			flex: isMobile ? undefined : 1,
			width: isMobile ? 150 : undefined,
			hideable: false,
			align: 'right',
			headerAlign: 'right',
			valueGetter: (_, row) => row.fields.Price,
			renderCell: (params) => (isValidNumber(params.value) ? d3.format('$,.2f')(params.value) : ''),
		},
		{
			field: 'QTY',
			headerName: 'QTY',
			filterable: false,
			flex: isMobile ? undefined : 1,
			width: isMobile ? 150 : undefined,
			hideable: false,
			align: 'right',
			headerAlign: 'right',
			valueGetter: (_, row) => row.fields.QTY,
			renderCell: (params) => (isValidNumber(params.value) ? d3.format(',.0f')(params.value) : ''),
		},
		{
			field: 'Block Size Ticket',
			headerName: 'Block Size',
			flex: isMobile ? undefined : 1,
			width: isMobile ? 150 : undefined,
			hideable: false,
			align: 'right',
			headerAlign: 'right',
			valueGetter: (_, row) => row.fields['Block Size Ticket'],
			renderCell: (params) => (isValidNumber(params.value) ? d3.format('$,.2f')(params.value) : ''),
			type: 'singleSelect',
			filterOperators: [
				{
					label: 'is',
					value: 'is',
					InputComponent: GridFilterInputSingleSelect,
				},
			],
			valueOptions: blockSizes.map((blockSize) => blockSize.label),
		},
	];

	useEffect(() => {
		// load parameters and data
		if (!isReadyToFilter) {
			const decodedFilterModel = decodeFilterModel(
				searchParams,
				columns.map((column) => column.field),
				arrayFilterKeys
			);
			const decodedPaginationModel = decodePaginationModel(searchParams, defaultPaginationModel);
			const decodedSortModel = decodeSortModel(searchParams, defaultSortModel);

			setFilterModel(decodedFilterModel);
			setPaginationModel(decodedPaginationModel);
			setSortModel(decodedSortModel);

			loadInquiries(decodedFilterModel, decodedPaginationModel, decodedSortModel).then(() => {
				setTimeout(() => {
					setReadyToFilter(true);
				}, 800);
			});
			setSelectedInquiries([]);
		}
	}, [searchParams]);

	useEffect(() => {
		if (isReadyToFilter) {
			setSearchParams(transformModelsToObject(filterModel, paginationModel, sortModel));
		}
	}, [filterModel, sortModel, paginationModel]);

	const loadInquiries = async (filterModel, paginationModel, sortModel) => {
		try {
			setLoading(true);

			const body = filterModel?.items?.reduce(
				(acc, filter) => {
					if (filter.value !== undefined && filter.value !== null) {
						if (filter.field === 'Block Size Ticket') {
							acc['blockSize'] = blockSizes.find((blockSize) => blockSize.label === filter.value);
						} else {
							acc[filter.field] = filter.value;
						}
					}
					return acc;
				},
				{
					page: paginationModel.page + 1,
					pageSize: paginationModel.pageSize,
					sort: sortModel[0]?.field ?? defaultSortModel.field,
					sortDirection: sortModel[0]?.sort ?? defaultSortModel.sort,
				}
			);

			const res = await apiClient.post(endpoints.getAnonymousInquiries, body);

			if (res) {
				setInquiries(res.data?.data ?? []);
				setTotalDataLength(res.data?.total ?? 0);
			}
		} catch (err) {
			toast.error(strings.errorMessages.inquiry.loadMany);
			return [];
		} finally {
			setLoading(false);
		}
	};

	const onSelectOne = (event, inquiryObj) => {
		if (event.target.checked) {
			setSelectedInquiries((prevState) => [...prevState, inquiryObj]);
		} else {
			setSelectedInquiries((prevState) => prevState.filter((item) => item.id !== inquiryObj.id));
		}
	};

	const isSelected = (inquiryObj) => Boolean(selectedInquiries.find((selected) => selected.id === inquiryObj.id));

	const handleLogInterest = async (selectedInquiries = []) => {
		if (selectedInquiries.length) {
			setCurrent({ selectedInquiries: selectedInquiries });
			navigate(`/company/${selectedInquiries[0].fields['Company Id'][0]}/send-inquiry`);
		}
	};

	const buyInquiries = () => {
		setBuyingLoading(true);
		handleLogInterest(selectedInquiries);
		setSelectedInquiries([]);
		setBuyingLoading(false);
	};

	const CustomToolbar = ({ selectedInquiries, buyInquiries, isBuyingLoading }) => {
		const primaryButton = (
			<LoadingButton
				variant="contained"
				size={isMobile ? 'large' : 'medium'}
				disabled={selectedInquiries.length === 0}
				onClick={buyInquiries}
				loading={isBuyingLoading}
				loadingPosition="end"
			>
				{`Express interest (${selectedInquiries.length})`}
			</LoadingButton>
		);

		const typography = (
			<Typography variant="body2">
				{selectedInquiries.length
					? `Selected ${selectedInquiries.length} inquiries`
					: 'Select inquiries to express interest'}
			</Typography>
		);

		return (
			<GridToolbarContainer sx={{ p: 1, pl: isMobile ? undefined : 2 }}>
				{!isMobile && (
					<Stack direction={'row'} alignItems="center" width="100%" justifyContent={'space-between'}>
						<GridToolbarFilterButton />
						<Stack direction={'row'} alignItems="center" spacing={2}>
							{typography}
							{primaryButton}
						</Stack>
					</Stack>
				)}
				{isMobile && (
					<Stack direction={'row'} alignItems="center" width="100%" justifyContent={'space-between'} spacing={2}>
						<IconButton color="primary" size="large" onClick={() => setMobileFiltersOpen(true)}>
							<Badge
								color="primary"
								badgeContent={filterModel && filterModel.items?.length}
								invisible={!(filterModel && filterModel.items?.length > 0)}
							>
								<FilterAlt fontSize="large" />
							</Badge>
						</IconButton>
						{primaryButton}
					</Stack>
				)}
			</GridToolbarContainer>
		);
	};

	const onFilterModelChange = useCallback(
		(newModel) => {
			setFilterModel(newModel);

			if (!compareFilterModels(newModel, filterModel)) {
				if (isReadyToFilter) {
					loadInquiries(newModel, defaultPaginationModel, sortModel);
					setPaginationModel(defaultPaginationModel);
				}
			}
		},
		[filterModel, isReadyToFilter]
	);

	const onPaginationModelChange = (newModel) => {
		if (isReadyToFilter) {
			loadInquiries(filterModel, newModel, sortModel);
			setPaginationModel(newModel);
		}
	};

	const onSortModelChange = (newModel) => {
		setSortModel(newModel);

		if (isReadyToFilter) {
			loadInquiries(filterModel, defaultPaginationModel, newModel);
			setPaginationModel(defaultPaginationModel);
		}
	};

	const handleMobileFilterChange = (field, getValue, operator = 'is') => {
		return (_, newValue) => {
			const updatedValue = getValue(newValue); // Extract the value based on the provided function
			const updatedItems = [...filterModel.items]; // Create a copy of the current items

			const itemIndex = updatedItems.findIndex((item) => item.field === field);

			if (updatedValue && (Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
				// If the value is not empty, update or add the item
				if (itemIndex > -1) {
					updatedItems[itemIndex].value = updatedValue; // Update existing item
				} else {
					// Add new item if it doesn't exist
					updatedItems.push({
						field,
						value: updatedValue,
						id: new Date().getTime(), // Generate unique ID
						operator,
					});
				}
			} else if (itemIndex > -1) {
				// If value is empty and the item exists, remove it
				updatedItems.splice(itemIndex, 1);
			}

			const newModel = { ...filterModel, items: updatedItems }; // Update the filter model with new items
			setFilterModel(newModel); // Set the new filter model
			loadInquiries(newModel, defaultPaginationModel, sortModel); // Trigger data reload
			setPaginationModel(defaultPaginationModel); // Reset pagination
		};
	};

	const onMobileCompanyFilterChange = useCallback(
		handleMobileFilterChange('companyIds', (value) => value.map((company) => company['Record Id']), 'is any'),
		[filterModel, sortModel]
	);

	const onMobileBlockSizeFilterChange = useCallback(
		handleMobileFilterChange('Block Size Ticket', (newValue) => (newValue ? newValue.label : '')),
		[filterModel, sortModel]
	);

	const onMobileBlockTypeFilterChange = useCallback(
		handleMobileFilterChange('blockType', (newValue) => newValue || ''),
		[filterModel, sortModel]
	);

	return (
		<>
			<PageTitle
				breadcrumbs={[
					{
						title: 'Market',
					},
				]}
				title={'All inquiries'}
			/>
			<TableContainer component={Card} sx={{ p: 0, overflow: 'visible' }}>
				<DataGridPro
					rows={inquiries}
					columns={columns}
					loading={isLoading}
					checkboxSelection
					disableRowSelectionOnClick
					filterMode={'server'}
					filterModel={filterModel}
					onFilterModelChange={onFilterModelChange}
					filterDebounceMs={800}
					paginationMode="server"
					paginationModel={paginationModel}
					onPaginationModelChange={onPaginationModelChange}
					rowCount={totalDataLength}
					pagination
					pageSizeOptions={[defaultPaginationModel.pageSize]}
					sortModel={sortModel}
					sortingMode={'server'}
					onSortModelChange={onSortModelChange}
					disableVirtualization
					disableColumnReorder
					disableColumnPinning
					disableColumnResize
					disableColumnMenu={isMobile}
					disableColumnFilter={isMobile}
					initialState={{ pinnedColumns: isMobile ? { left: [GRID_CHECKBOX_SELECTION_FIELD] } : undefined }}
					slots={{
						toolbar: () => (
							<CustomToolbar
								selectedInquiries={selectedInquiries}
								buyInquiries={buyInquiries}
								isBuyingLoading={isBuyingLoading}
							/>
						),
						noRowsOverlay: () => (
							<Stack spacing={3} alignItems={'center'} height={'100%'} justifyContent={'center'} p={2}>
								{!isMobile && <img src={'/illustrations/dark/notFound.svg'} style={{ height: 160 }} alt="Not found" />}
								<Stack spacing={1} alignItems={'center'}>
									<Typography variant={'h5'}>No inquiries found</Typography>
									<Typography variant={'body2'} textAlign={'center'}>
										We couldn't find what you searched for. Change your filter criteria.
									</Typography>
								</Stack>
							</Stack>
						),
					}}
					slotProps={{
						filterPanel: {
							logicOperators: [],
						},
					}}
					sx={{
						'& .MuiDataGrid-row:has(input[type="checkbox"]:checked)': {
							backgroundColor: alpha(theme.palette.primary.main, 0.1),
						},
						'& .MuiDataGrid-row:has(input[type="checkbox"][data-indeterminate="true"])': {
							backgroundColor: alpha(theme.palette.primary.main, 0.1),
						},
					}}
				/>
			</TableContainer>
			{isMobile && (
				<SwipeableDrawer
					anchor="bottom"
					elevation={3}
					open={isMobileFiltersOpen}
					onClose={() => setMobileFiltersOpen(false)}
					ModalProps={{
						keepMounted: true,
					}}
					PaperProps={{
						style: {
							borderTopLeftRadius: 24,
							borderTopRightRadius: 24,
						},
					}}
				>
					<Puller />
					<Stack p={3} pb={'50px'} spacing={3}>
						<Typography variant={'h5'}>Filters</Typography>
						<Stack spacing={1}>
							<Autocomplete
								multiple
								options={companies}
								getOptionLabel={(option) => option['Legal Name']}
								isOptionEqualToValue={(option, value) => option['Record Id'] === value['Record Id']}
								value={companies.filter((company) =>
									(filterModel.items.find((item) => item.field === 'companyIds')?.value ?? []).includes(
										company['Record Id']
									)
								)}
								onChange={onMobileCompanyFilterChange}
								renderInput={(params) => <TextField {...params} label="Company" placeholder="Select companies" />}
							/>
							<Autocomplete
								options={constants.USER.USER_STAGE_GLOBAL}
								value={filterModel.items.find((item) => item.field === 'blockType')?.value || null}
								onChange={onMobileBlockTypeFilterChange}
								renderInput={(params) => <TextField {...params} label="Block type" placeholder="Select block size" />}
							/>
							<Autocomplete
								options={blockSizes}
								getOptionLabel={(option) => option.label}
								isOptionEqualToValue={(option, value) => option.label === value.label}
								value={
									blockSizes.find(
										(blockSize) =>
											filterModel.items.find((item) => item.field === 'Block Size Ticket')?.value === blockSize.label
									) || null
								}
								onChange={onMobileBlockSizeFilterChange}
								renderInput={(params) => <TextField {...params} label="Block size" placeholder="Select block size" />}
							/>
						</Stack>
					</Stack>
				</SwipeableDrawer>
			)}
		</>
	);
};

export default MarketPageView;
