import {
	Button,
	Card,
	Grid,
	Link,
	Menu,
	MenuItem,
	Skeleton,
	Stack,
	TableContainer,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Add, MoreVert } from '@mui/icons-material';
import { Link as RouterLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { apiClient, endpoints } from '../../../../utils';
import { toast } from 'react-toastify';
import { NotFoundContainer } from '../../../../components/CompanyList/companyList.styles';
import EntityDrawer from './components/EntityDrawer';
import DeleteDialog from './components/DeleteDialog';
import { strings } from '../../../../utils/strings';
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';

const EntitiesTab = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const defaultTheme = useTheme();
	const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));

	const [isReadyToLoad, setReadyToLoad] = useState(false);
	const [isFirstLoading, setFirstLoading] = useState(true);

	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);

	const [nameFilter, setNameFilter] = useState('');

	const [selectedObj, setSelectedObj] = useState(null);
	const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

	const [isDeleteOpen, setDeleteOpen] = useState(false);

	useEffect(() => {
		// firstly data
		loadData().then((res) => {
			setFilteredData(res);
			setData(res);
			isFirstLoading && setFirstLoading(false);
		});
	}, []);

	useEffect(() => {
		// secondly load parameters
		const nameParam = searchParams.get('name');
		if (nameParam && nameParam.trim()) {
			setNameFilter(nameParam.trim());
		} else {
			setNameFilter('');
		}

		!isReadyToLoad &&
			setTimeout(() => {
				setReadyToLoad(true);
			}, 300);
	}, [searchParams]);

	useEffect(() => {
		//thirdly filter data
		if (isReadyToLoad) {
			if (nameFilter) {
				setFilteredData(
					data.filter((item) => {
						return item.fields && item.fields.Name && item.fields.Name.toLowerCase().includes(nameFilter.toLowerCase());
					})
				);
			} else {
				setFilteredData(data);
			}
		}
	}, [setFilteredData, isReadyToLoad, data, nameFilter]);

	useEffect(() => {
		//deletion
		if (location?.state?.deleteId) {
			setData((prevState) => prevState.filter((item) => item.id !== location?.state?.deleteId));
			setFilteredData((prevState) => prevState.filter((item) => item.id !== location?.state?.deleteId));
		}
	}, [location.pathname, location?.state?.deleteId]);

	useEffect(() => {
		//update
		const updateObjectId = location?.state?.updateObjectId;
		if (
			updateObjectId &&
			(updateObjectId === 'new' || filteredData.find((obj) => obj.id === location?.state?.updateObjectId))
		) {
			loadData().then((res) => {
				const found = res.find((resItem) => resItem.id === location?.state?.updateObjectId);
				if (found) {
					setData((prevState) =>
						prevState.map((item) => {
							if (item.id === location?.state?.updateObjectId) {
								return found ?? item;
							} else {
								return item;
							}
						})
					);

					setFilteredData((prevState) =>
						prevState.map((item) => {
							if (item.id === location?.state?.updateObjectId) {
								return found ?? item;
							} else {
								return item;
							}
						})
					);
				} else {
					setData(res);
					setFilteredData(res);
				}
			});
		}
	}, [location.pathname, location?.state?.updateObjectId]);

	const columns = [
		{
			field: 'name',
			headerName: 'Name',
			flex: isMobile ? undefined : 1,
			width: isMobile ? 160 : undefined,
			renderCell: (params) => (
				<Link
					underline="hover"
					variant="body2"
					component={RouterLink}
					to={`/settings/entities/${params?.row.id}${window.location.search}`}
					state={{ preventScrollReset: true }}
				>
					{params?.row.fields['Name']}
				</Link>
			),
		},
		{
			field: 'address',
			headerName: 'Address',
			flex: isMobile ? undefined : 1,
			width: isMobile ? 160 : undefined,
			valueGetter: (_, row) => row.fields.Address,
		},
		{
			field: 'signatoryName',
			headerName: 'Signatory Name',
			flex: isMobile ? undefined : 1,
			width: isMobile ? 160 : undefined,
			valueGetter: (_, row) => row.fields['Signatory Name'],
		},
		{
			field: 'signatoryEmail',
			headerName: 'Signatory Email',
			flex: isMobile ? undefined : 1,
			width: isMobile ? 160 : undefined,
			valueGetter: (_, row) => row.fields['Signatory Email'],
		},
		{
			field: 'signatoryTitle',
			headerName: 'Signatory Title',
			flex: isMobile ? undefined : 1,
			width: isMobile ? 160 : undefined,
			valueGetter: (_, row) => row.fields['Signatory Title'],
		},
		{
			field: 'actions',
			type: 'actions',
			width: 60,
			getActions: (params) => [
				<GridActionsCellItem
					size={'large'}
					icon={<MoreVert sx={{ color: 'text.secondary' }} />}
					onClick={(e) => handleActionsClick(e, params.row)}
					label={'actions'}
				/>,
			],
		},
	];

	const loadData = async () => {
		try {
			const res = await apiClient.get(endpoints.entities);
			return res.data ?? [];
		} catch (err) {
			toast.error(strings.errorMessages.entity.loadMany);
			return [];
		}
	};

	const handleActionsClick = (event, obj) => {
		setSelectedObj(obj);
		setMenuAnchorEl(event.currentTarget);
	};

	const handleViewDetails = () => {
		navigate('/settings/entities/' + selectedObj.id + window.location.search, {
			state: { preventScrollReset: true },
		});
		setMenuAnchorEl(null);
	};

	const addNew = () => {
		navigate('/settings/entities/new' + window.location.search, {
			state: { preventScrollReset: true },
		});
	};

	const primaryButton = (
		<Button
			size={isMobile ? 'large' : 'medium'}
			variant="contained"
			onClick={addNew}
			startIcon={<Add />}
			sx={
				isMobile
					? {
							position: 'fixed',
							bottom: 24,
							left: 24,
							right: 24,
							zIndex: 1051,
							height: 48,
							boxShadow: defaultTheme.shadows[24],
					  }
					: undefined
			}
		>
			Add new entity
		</Button>
	);

	return (
		<Grid container rowSpacing={2} columnSpacing={3}>
			{isFirstLoading && (
				<Grid item xs={12}>
					<Skeleton variant="rectangular" height={300} />
				</Grid>
			)}
			{!isFirstLoading && !data.length && (
				<Grid item xs={12}>
					<NotFoundContainer>
						<Stack spacing={3} alignItems={'center'}>
							<img src={'/illustrations/dark/userPlus.svg'} style={{ height: 200 }} alt="Not found" />
							<Stack spacing={3} alignItems={'center'}>
								<Typography variant={'h5'}>No entities found</Typography>
								{primaryButton}
							</Stack>
						</Stack>
					</NotFoundContainer>
				</Grid>
			)}
			{!isFirstLoading && data.length > 0 && (
				<>
					<Grid item xs={isMobile ? 12 : 6}>
						<TextField
							fullWidth
							variant={'outlined'}
							value={nameFilter}
							onChange={(e) => {
								const value = e.target.value;

								setSearchParams(
									value
										? {
												name: value,
										  }
										: {}
								);
							}}
							label={'Name'}
						/>
					</Grid>
					{!isMobile && (
						<Grid item xs={6}>
							<Stack alignItems={'flex-end'} justifyContent={'center'} height={'100%'}>
								{primaryButton}
							</Stack>
						</Grid>
					)}
					{isMobile && primaryButton}
					<Grid item xs={12}>
						<TableContainer component={Card} sx={{ p: 0, overflow: 'visible' }}>
							<DataGridPro
								rows={filteredData}
								columns={columns}
								initialState={{ pinnedColumns: isMobile ? { right: ['actions'] } : undefined }}
								autoHeight
								disableSelectionOnClick
								hideFooter
								disableColumnMenu
								disableColumnSorting
								disableColumnReorder
								disableColumnResize
							/>
						</TableContainer>

						<Menu
							anchorEl={menuAnchorEl}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'center',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'center',
							}}
							open={Boolean(menuAnchorEl)}
							onClose={() => {
								setMenuAnchorEl(null);
								selectedObj(null);
							}}
						>
							<MenuItem onClick={handleViewDetails}>View details</MenuItem>
							<MenuItem
								sx={{ color: defaultTheme.palette.error.main }}
								onClick={() => {
									setDeleteOpen(true);
									setMenuAnchorEl(null);
								}}
							>
								Delete
							</MenuItem>
						</Menu>
						<DeleteDialog
							object={selectedObj}
							onSuccess={() => {
								setFilteredData((prevState) => prevState.filter((item) => item.id !== selectedObj.id));
								setData((prevState) => prevState.filter((item) => item.id !== selectedObj.id));
								setSelectedObj(null);
							}}
							open={isDeleteOpen}
							handleClose={() => {
								setDeleteOpen(false);
								setMenuAnchorEl(null);
								setSelectedObj(null);
							}}
						/>
					</Grid>
				</>
			)}
			<EntityDrawer entities={data} />
		</Grid>
	);
};

export default EntitiesTab;
